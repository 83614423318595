import {
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';

import request from '../../../utils/request';
import initialState from '../initialState';

export default function useList(url, updateUrl) {
  const [list, setList] = useState({
    count: initialState.count,
    results: initialState.items
  });

  useEffect(() => {
    (async () => {
      try {
        setList(await request(url));
      } catch (e) {
        // TODO: handle error
        console.error(e);
      }
    })();
  }, [setList, url]);

  const items = useMemo(() => list.results, [list]);
  const count = useMemo(() => list.count, [list]);

  const changeItem = useCallback(async (id, change) => {
    const updatedItem = await request(`${updateUrl}${id}/`, 'PATCH', change);

    const index = items.findIndex((item) => item.id === updatedItem.id);

    setList((prevList) => ({
      ...prevList,
      results: [
        ...prevList.results.slice(0, index),
        updatedItem,
        ...prevList.results.slice(index + 1)
      ]
    }));
  }, [updateUrl, items]);

  return {
    items,
    count,
    changeItem
  };
}
