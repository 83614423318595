import React from 'react';
import ReactDOM from 'react-dom';

import withErrorBoundary from '../withErrorBoundary';
import ManifestContainer from './ManifestContainer';

const element = document.querySelector('.react-manifest');

if (element) {
  ReactDOM.render(
    React.createElement(withErrorBoundary(ManifestContainer), {
      manifestId: Number(element.dataset.manifestId),
      concludeClassificationUrl: element.dataset.concludeClassificationUrl
    }),
    element
  );
}
