import {
  Server, Model, Factory, Response, hasMany, belongsTo
} from 'miragejs';

export default function configureServer() {
  return new Server({
    models: {
      manifest: Model.extend({
        guides: hasMany()
      }),

      guide: Model.extend({
        manifest: belongsTo()
      })
    },
    factories: {
      guide: Factory.extend({
        originGuideNumber() {
          return (
            Math.floor(Math.random() * (1999999999 - 1000000000 + 1))
            + 1000000000
          );
        },
        originalCustomsStatus() {
          const statuses = ['L', 'D', 'F', 'R', 'C'];
          return statuses[Math.floor(Math.random() * statuses.length)];
        },
        packagesAmount() {
          return Math.floor(Math.random() * 5) + 1;
        },
        content() {
          const contents = [
            'Teléfono 32GB',
            'Accesorio ropa',
            'Laptop Asus',
            'SSD',
            'Macbook Pro'
          ];
          return contents[Math.round(Math.random() * (contents.length - 1))];
        },
        weight() {
          return Number((Math.random() * 10.0).toFixed(2));
        },
        updatedAt() {
          return Date.now();
        },
        scannedPackagesAmount: 0,
        potentialClassification() {
          const statuses = ['L', 'D', 'F', 'R', 'C'];
          return statuses[Math.floor(Math.random() * statuses.length)];
        }
      })
    },

    seeds(server) {
      const manifest = server.create('manifest', {
        id: 1,
        name: 'manifest 1'
      });

      server.createList('guide', 5, { manifest });
    },

    routes() {
      this.get('/api/v1/manifests/:id/guides', (schema, request) => {
        const { id: manifestId } = request.params;
        const manifest = schema.manifests.find(manifestId);
        return manifest.guides.models;
      });
      this.put(
        '/api/v1/manifests/:id/guides/:guideNumber/scan/',
        (schema, request) => {
          const {
            id: manifestId,
            guideNumber: originGuideNumber
          } = request.params;

          const manifest = schema.manifests.find(manifestId);
          const guide = manifest.guides.models.find(
            (model) => model.originGuideNumber === Number(originGuideNumber)
          );

          if (!guide) {
            // TODO: change the error response
            return new Response(
              400,
              {},
              {
                error: 'guide/not-found'
              }
            );
          }

          guide.scannedPackagesAmount += 1;
          guide.updatedAt = Date.now();
          guide.save();

          return guide.attrs;
        }
      );
    }
  });
}
