import React, { useState } from 'react';

import { useDesignContext } from '../context/DesignContext';
import { useListContext } from '../context/ListContext';
import SortIcon from './SortIcon';

export default function DataTableHeader() {
  const { columns, actions, flags } = useDesignContext();
  const { selectAllItems } = useListContext();
  const [check, setCheck] = useState(false);

  const onCheckAllChange = (newValue) => {
    setCheck(newValue);
    selectAllItems(newValue);
  };

  return (
    <div className="row grid-header">
      {flags.isSelectable && (
        <div className="grid-header-select">
          <input
            id="check-all"
            type="checkbox"
            className="form-control"
            value={check}
            onChange={() => onCheckAllChange(!check)}
          />
        </div>
      )}
      {actions && (
        <div className="grid-header-actions">
          {gettext('Actions')}
        </div>
      )}
      <div className="grid-header-content">
        {columns.map((column) => (
          <div
            key={`column-header-${column.key}`}
            className={`col-${column.distribution}`}
          >
            {column.name}
            {column.isSortable && (
              <SortIcon fieldKey={column.key} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
