import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorModal(
  {
    error,
    onClose
  }
) {
  return (
    <div className="card w-75 h-75">
      <div className="card-header">
        {error}
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label={error}
          onClick={() => onClose()}
        >
          <span aria-hidden="true">
            <i className="fas fa-times" />
          </span>
        </button>
      </div>
      <div className="card-body text-danger d-flex justify-content-center align-items-center">
        <p className="card-text display-1 d-block text-center">{error}</p>
      </div>
      <div className="card-footer text-right">
        <button
          type="button"
          className="btn btn-secondary mr-1"
          data-dismiss="modal"
          onClick={() => onClose()}
        >
          {gettext('close')}
        </button>
      </div>
    </div>
  );
}

ErrorModal.propTypes = {
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired
};
