export default function prepareProps(dataset) {
  const columns = (dataset.columnColumns || '').split(',');
  const headers = (dataset.columnHeaders || '').split(',');
  const distribution = (dataset.columnDistribution || '')
    .split(',')
    .map((value) => Number(value));
  const truncate = (dataset.columnTruncate || '')
    .split(',')
    .reduce((memo, field) => ({ ...memo, [field]: true }), {});
  const sortable = (dataset.columnSortable || '')
    .split(',')
    .reduce((memo, field) => ({ ...memo, [field]: true }), {});
  const editable = (dataset.columnEditable || '')
    .split(',')
    .reduce((memo, field) => ({ ...memo, [field]: true }), {});

  const actionsConfig = dataset.actionView || dataset.actionEdit || dataset.actionDelete
    ? {
      actions: {
        view: dataset.actionView,
        edit: dataset.actionEdit,
        delete: dataset.actionDelete
      }
    }
    : {};

  return {
    initialUrl: dataset.initialUrl,
    updateUrl: dataset.updateUrl || dataset.initialUrl,
    flags: {
      isDisabledPagination: !!dataset.flagDisabledPagination,
      isSelectable: !!dataset.flagSelectable
    },
    columns: columns.map((column, index) => {
      let keySetting = {
        key: column
      };

      if (column.includes(':')) {
        const [key, type, typeTransformer] = column.split(':');
        // TODO: validate field composition
        keySetting = {
          key,
          type,
          typeTransformer
        };
      }

      return {
        ...keySetting,
        name: gettext(headers[index]),
        distribution: distribution[index],
        isTruncate: !!truncate[keySetting.key],
        isSortable: !!sortable[keySetting.key],
        isEditable: !!editable[keySetting.key]
      };
    }),
    ...actionsConfig
  };
}
