import request, { Method } from '../../../utils/request';

export default {
  async all(manifestId) {
    return request(`manifests/${manifestId}/guides`);
  },
  async scan(manifestId, guideNumber) {
    return request(`manifests/${manifestId}/guides/${guideNumber}/scan/`, Method.PUT);
  }
};
