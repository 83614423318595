import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import DataTableContainer from './DataTableContainer';
import createBridge from '../createBridge';
import withErrorBoundary from '../withErrorBoundary';
import prepareProps from './utils/prepareProps';

const containers = document.querySelectorAll('.react-table-container');

containers.forEach((container) => {
  const element = container.querySelector('.react-table');

  const {
    updateState,
    BridgeComponent
  } = createBridge(DataTableContainer, prepareProps(element.dataset));

  ReactDOM.render(
    React.createElement(withErrorBoundary(BridgeComponent)),
    element
  );

  const action = container.querySelector('.react-table-update-props');

  if (action) {
    action.addEventListener('click', (event) => {
      event.preventDefault();

      const filterProps = Array.from(
        container.querySelectorAll('.react-table-prop')
      )
        .map((prop) => {
          const value = (prop.value && prop.dataset.dateOutputFormat)
            ? moment(prop.value, prop.dataset.dateFormat).format(prop.dataset.dateOutputFormat)
            : prop.value;

          return {
            value,
            name: prop.name
          };
        });
      updateState({ filters: filterProps });
    });
  }
});
