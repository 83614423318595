import { normalize, schema } from 'normalizr';

const GuideEntity = new schema.Entity('guides');

export default function normalizeGuides(response) {
  const {
    entities: { guides }
  } = normalize(response, [GuideEntity]);
  return guides;
}
