import {
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useState
} from 'react';
import moment from 'moment';

import GuideApi from '../api/GuideApi';
import normalizeGuides from '../utils/normalizeGuides';
import App from '../../../app';

const reducer = (state, action) => {
  switch (action.type) {
    case 'store':
      return action.payload;

    case 'update':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export default function useGuides(manifestId, onScanSuccess, onScanError) {
  const [state, dispatch] = useReducer(reducer, {});
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const guides = await GuideApi.all(manifestId);
        dispatch({
          type: 'store',
          payload: normalizeGuides(guides)
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [manifestId]);

  const guides = useMemo(
    () => Object.values(state)
      .sort((a, b) => moment(b.lastScanDatetime).diff(moment(a.lastScanDatetime))),
    [state]
  );

  const scans = useMemo(
    () => Object.values(state).reduce(
      (sum, guide) => sum + (guide.scannedPackagesAmount >= guide.packagesAmount ? 1 : 0),
      0
    ),
    [state]
  );

  const onScan = useCallback(
    async (guideNumber) => {
      try {
        const guide = await GuideApi.scan(manifestId, guideNumber);
        dispatch({ type: 'update', payload: guide });

        if (guide.potentialClassification === 'F') {
          throw new Error(gettext('guide with physical classification'));
        } else if (guide.potentialClassification === 'R') {
          throw new Error(gettext('guide with retained classification'));
        }

        if (onScanSuccess) {
          onScanSuccess();
        }
      } catch (requestError) {
        setError(requestError.message);
        if (onScanError) {
          onScanError();
        }
      } finally {
        App.utils.hideLoadingButtons();
      }
    },
    [manifestId, onScanError, onScanSuccess]
  );

  const clearError = () => setError(undefined);

  return {
    data: {
      guides,
      scans,
      error
    },
    actions: {
      onScan,
      clearError
    }
  };
}
