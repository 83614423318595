import Cookies from 'js-cookie';

export default function getTable(url, method = 'GET', body) {
  const csrfToken = Cookies.get('csrftoken');
  const bodyConfig = body
    ? { body: JSON.stringify(body) }
    : {};

  return fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken
    },
    mode: 'same-origin',
    ...bodyConfig
  })
    .then(async (response) => {
      if (!response.ok) {
        const json = await response.json();
        const error = new Error(json.error);
        error.raw = json;
        throw error;
      }
      return response.json();
    });
}
