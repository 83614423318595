import React from 'react';

import { useListContext } from '../context/ListContext';

import DataTableHeader from './DataTableHeader';
import DataTableRow from './DataTableRow';
import DataTablePagination from './DataTablePagination';
import DataTableLimit from './DataTableLimit';
import { useDesignContext } from '../context/DesignContext';

export default function DataTable() {
  const { flags } = useDesignContext();
  const { items } = useListContext();

  return (
    <div className="data-table grid mt-e3">
      <DataTableHeader />
      {items.map((item) => (
        <DataTableRow
          key={`item-${item.id}`}
          item={item}
        />
      ))}
      {!flags.isDisabledPagination && (
        <div className="row mt-e3">
          <div className="col-md-6">
            <DataTableLimit />
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <DataTablePagination />
          </div>
        </div>
      )}
    </div>
  );
}
