import React from 'react';

export function withBridge(WrappedComponent, initialState) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = initialState;
    }

    setExternalState(external) {
      this.setState(external);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.state} />;
    }
  };
}

export default function createBridge(Component, state) {
  const ref = React.createRef();

  const BridgeComponent = React.memo(withBridge(Component, state), [state]);

  return {
    updateState(externalState) {
      if (ref.current) {
        ref.current.setExternalState(externalState);
      }
    },
    BridgeComponent() {
      return <BridgeComponent ref={ref} />;
    }
  };
}
