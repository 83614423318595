$(() => {
  $('.datetimepicker-input').each((i, item) => {
    const $this = $(item);
    $this.data('target', `#${$this.prop('id')}`);

    $this.datetimepicker({
      date: moment($this.val(), 'DD/MM/YYYY'),
      format: $this.data('format'),
      locale: 'es'
    });
  });

  $('.datetimepicker-range-input').each((i, item) => {
    const $element = $(item);
    const $start = $element.find('.datetimepicker-range-start');
    const $end = $element.find('.datetimepicker-range-end');

    if (!$start.length && !$end.length) {
      return;
    }

    $start
      .data('target', `#${$start.prop('id')}`)
      .datetimepicker({
        locale: 'es'
      });

    $end
      .data('target', `#${$end.prop('id')}`)
      .datetimepicker({
        locale: 'es',
        useCurrent: false
      });

    $start.on('change.datetimepicker', (e) => {
      $end.datetimepicker('minDate', e.date);
    });

    $end.on('change.datetimepicker', (e) => {
      $start.datetimepicker('maxDate', e.date);
    });
  });
});
