import { useState } from 'react';

import initialState from '../initialState';
import updateExternalSelectedInput from '../../../utils/updateExternalSelectedInput';

export default function useSelection(items) {
  const [selected, setSelected] = useState(initialState.selected);

  const toggleSelectedItem = (id) => setSelected((prevSelected) => {
    if (prevSelected[id]) {
      const {
        [id]: ignored,
        ...newSelected
      } = prevSelected;

      updateExternalSelectedInput(newSelected);
      return newSelected;
    }

    const newSelected = {
      ...prevSelected,
      [id]: true
    };

    updateExternalSelectedInput(newSelected);
    return newSelected;
  });

  const selectAllItems = (select) => {
    if (!select) {
      setSelected({});

      updateExternalSelectedInput({});
      return;
    }

    const newSelected = items.reduce((memo, item) => ({
      ...memo,
      [item.id]: true
    }), {});

    updateExternalSelectedInput(newSelected);
    setSelected(newSelected);
  };

  return {
    selected,
    toggleSelectedItem,
    selectAllItems
  };
}
