import React from 'react';

export default function withErrorBoundary(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({
        error,
        errorInfo
      });
    }

    render() {
      const {
        error,
        errorInfo
      } = this.state;

      if (errorInfo) {
        return (
          <div>
            <h2>{gettext('Something went wrong.')}</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {error && error.toString()}
              <br />
              {errorInfo.componentStack}
            </details>
          </div>
        );
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  };
}
