import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import './styles/overlay.scss';

export default function Overlay({ show = false, children }) {
  if (!show) {
    return null;
  }

  return createPortal(
    <div className="react-overlay-content">
      <div className="react-overlay-back" />
      {children}
    </div>,
    document.querySelector('.react-overlay')
  );
}

Overlay.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
