$(() => {
  const getManifestDuties = function getManifestDuties() {
    const manifestId = this.value;
    const amountInput = document.querySelector('#id_amount');
    amountInput.value = '';

    fetch(`/api/v1/manifests/${manifestId}/`).then(
      (res) => res.json()
    ).then(
      (data) => { amountInput.value = data.estimatedDuties; }
    ).catch(() => { amountInput.value = ''; });
  };

  const $element = $('#id_manifest');
  !!$element && $element.select2().on('change', getManifestDuties);
});
